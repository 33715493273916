<script lang='ts'>
import { faShoppingCart } from '@igefa-e-commerce/pattern-library-icons/fa/pro-solid-svg-icons'
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import { onDestroy, onMount } from 'svelte'
import { apiClient } from '../../../apiClient'
import { basketChannel } from '../../../channels'
import type { BasketSizeUpdate } from '../../../channels/types'
import { pages } from '../../../pages'

$: numberOfArticles = 0

const channel = new BroadcastChannel(basketChannel.name)

const handleBasketUpdate = (event: MessageEvent) => {
  const basketSizeUpdate = event.data as BasketSizeUpdate
  if ('size' in basketSizeUpdate) {
    numberOfArticles = basketSizeUpdate.size
  }
}

onMount(() => {
  if (typeof window !== 'undefined') {
    channel.addEventListener('message', handleBasketUpdate)
    fetchSize()
  }
})

onDestroy(() => {
  if (typeof window !== 'undefined') {
    channel.removeEventListener('message', handleBasketUpdate)
  }
})

async function fetchSize() {
  const sizeResult = await apiClient.basket.size()

  if (sizeResult.isOk()) {
    numberOfArticles = sizeResult.unwrap().numberOfArticles
  }
}

const redirectToBasket = () => {
  window.location.href = pages.basket
}
</script>

<div on:click={redirectToBasket} class='icon' data-testid='basket-mini-basket'>
  <Button class="shopping-cart-button" icon={faShoppingCart} iconType="top" variant='tertiary'/>

  {#if numberOfArticles > 0}
    <span class='basket-icon-badge'>{numberOfArticles}</span>
  {/if}
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/colors.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/fonts.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';

  .icon {
    position: relative;
    width: fit-content;
    height: fit-content;

    .basket-icon-badge {
      position: absolute;
      background-color: colors.$color_helper_sale;
      color: white;
      font-size: 0.7rem;
      padding: 0 0.4em;
      border-radius: 2em;
      text-align: center;
      top: -6px;
      right: -6px;
      height: 20px;
      line-height: 20px;
      min-width: 20px;
      box-sizing: border-box;
      max-width: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
